import { FunctionComponent } from 'react';
import cn from 'classnames';
import {
  richTextSerializer,
  richTextSerializerWithOptions,
} from '@ee-monorepo/prismic/shared/serializers';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import {
  SliceWrapper,
  ImageWrapper,
  TooltipWithPopper,
} from '@ee-monorepo/prismic/shared/ui';
import { PrismicButton } from '@ee-monorepo/prismic/shared/ui';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import { colors } from '@snap/colors';

export interface ChannelsBlockProps {
  slice?: {
    items?: [
      {
        description?: PrismicFields.RichTextField;
        title?: PrismicFields.RichTextField;
        title_color?: PrismicFields.Select;
        icon?: PrismicFields.Image;
        button_label?: PrismicFields.KeyTextField;
        button_link?: PrismicFields.Link;
        button_inverted_variant?: boolean;
        button_variant?: PrismicFields.ButtonVariant;
        add_padding?: boolean;
        disclaimer_text?: PrismicFields.RichTextField;
      }
    ];
    primary?: {
      header_title?: PrismicFields.RichTextField;
      items_per_row_desktop?: PrismicFields.Select;
      items_per_row_mobile?: PrismicFields.Select;
      icon_alignment?: PrismicFields.Select;
      bg_color?: PrismicFields.Select;
      action_button_label?: PrismicFields.KeyTextField;
      action_button_link?: PrismicFields.Link;
      action_button_color?: PrismicFields.Select;
      action_button_inverted_variant?: boolean;
      action_button_variant?: PrismicFields.Select;
    };
    variation?: 'default-slice' | 'newDesignWithButton';
  };
}

export const ChannelsBlock: FunctionComponent<ChannelsBlockProps> = ({
  slice,
}) => {
  const isMobile = useHandHeld();
  const { items, primary, variation } = slice;
  const hasActionButton =
    primary?.action_button_label && primary?.action_button_link;
  const items_per_row_desktop: number =
    parseInt(primary?.items_per_row_desktop) || 2; // 2,3,4
  const items_per_row_mobile: number =
    parseInt(primary?.items_per_row_mobile) || 1; // 1,2
  return (
    <div
      className={cn(
        'py-4',
        'md:py-1',
        getColor(primary.bg_color).backgroundColorClass,
        {
          'mt-0': isMobile,
        }
      )}
    >
      <SliceWrapper
        data-testid="channelsBlockContainer"
        className={cn('snap-container', 'lg:my-8')}
      >
        {prismicH.isFilled.richText(primary?.header_title) && (
          <div
            className={cn(
              'flex',
              'xs:justify-start md:justify-center',
              variation === 'newDesignWithButton' ? 'pb-2' : 'pb-8',
              'md:pb-16',
              'text-SnapDarkBlue'
            )}
            data-testid="channelsBlockContainer-title"
          >
            <PrismicRichText
              field={primary?.header_title}
              components={richTextSerializer}
            />
          </div>
        )}
        <section className="row md:flex-wrap">
          {items?.map((item, i) => {
            const paddingTop =
              i === 0
                ? item.add_padding && !isMobile
                  ? 'p-10'
                  : `pt-8 ${
                      variation === 'newDesignWithButton'
                        ? 'md:pt-4'
                        : 'md:pt-0'
                    }`
                : isMobile
                ? 'pt-8 md:pt-0'
                : `pt-16 ${
                    variation === 'newDesignWithButton' ? 'md:pt-4' : 'md:pt-0'
                  }`;
            return (
              <div
                data-testid="channelsBlockItem"
                className={cn(
                  paddingTop,
                  `${items_per_row_mobile === 1 ? 'w-full' : 'w-1/2'}`,
                  `md:w-1/${items_per_row_desktop}`,
                  `flex shrink ${
                    variation === 'newDesignWithButton'
                      ? 'flex-row md:flex-col xs:justify-between md:justify-start'
                      : 'flex-col'
                  }`,
                  primary?.icon_alignment != null &&
                    `text-${primary?.icon_alignment}`,
                  `${
                    variation === 'newDesignWithButton' && isMobile
                      ? `xs:items-start md:items-${primary?.icon_alignment}`
                      : `items-${primary?.icon_alignment}`
                  }`
                )}
                key={i}
              >
                {item?.icon?.url && (
                  <div className={'pb-4'}>
                    <ImageWrapper
                      alt={item?.icon?.alt}
                      src={item?.icon?.url}
                      width={64}
                      height={64}
                    />
                  </div>
                )}
                <div
                  className={cn(
                    'content',
                    variation === 'newDesignWithButton'
                      ? 'sm:text-left md:text-center xs:w-[calc(100%-80px)] md:w-[100%]'
                      : 'text-center'
                  )}
                >
                  <div
                    data-testid="channelsBlock-title"
                    className="text-SnapDarkBlue pb-1"
                  >
                    <PrismicRichText
                      field={item?.title}
                      components={richTextSerializer}
                    />
                  </div>
                  <div data-testid="channelsBlock-desc">
                    {prismicH.isFilled.richText(item?.disclaimer_text) ? (
                      <TooltipWithPopper
                        mainContent={item?.description}
                        mainContentSerializer={richTextSerializerWithOptions()}
                        popperContent={item?.disclaimer_text}
                        elementType="heading5"
                        mainContentColor="text-SnapGrey500"
                        iconTooltipColor={colors.SnapDarkBlue}
                        toolTipFilled
                      />
                    ) : (
                      <PrismicRichText
                        field={item?.description}
                        components={richTextSerializer}
                      />
                    )}
                  </div>
                  <div>
                    {item?.button_label && item?.button_link && (
                      <PrismicButton
                        size="compact"
                        data-testid="channelsBlock-button"
                        link={item?.button_link}
                        variant={item?.button_variant}
                        inverseMode={item?.button_inverted_variant}
                        label={item?.button_label}
                        showButton={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <div className={cn({ 'mt-6 text-center': hasActionButton })}>
          {hasActionButton && (
            <PrismicButton
              variant={
                primary?.action_button_variant as PrismicFields.ButtonVariant
              }
              inverseMode={primary?.action_button_inverted_variant}
              link={primary?.action_button_link}
              showButton={true}
              fullWidth={isMobile}
              label={primary?.action_button_label}
              buttonClassName={`border !border-${primary?.action_button_color}-500`}
              data-testid="actionButton"
            />
          )}
        </div>
      </SliceWrapper>
    </div>
  );
};
